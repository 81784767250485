import { Observable, of } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import AuthApiService from '@shared/services/auth/auth-api.service';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';

export default class MathAsyncValidators {
  static isEmailTaken(authApiService: AuthApiService): (param: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl) => {
      if (!control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        switchMap(value => authApiService.checkIsEmailTaken(value)),
        map((isTaken: boolean) => isTaken ? { emailTaken: true } : null),
        first(),
      );
    }
  }

  static isUsernameTaken(authApiService: AuthApiService): (param: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl) => {
      if (!control.value) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(500),
        switchMap(value => authApiService.checkIsUsernameTaken(value)),
        map((isTaken: boolean) => isTaken ? { usernameTaken: true } : null),
        first(),
      );
    }
  }
}
